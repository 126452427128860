import React from "react";

import "./HomeFooter.scss";

const HomeFooter = () => {
  return (
    <div className="Home__bottom">
      <div className="Home__IT">KNOCK.IT 2023</div>
    </div>
  );
};

export default HomeFooter;
