import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import { Button, Table, Tag } from "antd";
import { selectedVouchers } from "../../../../redux/actions/vouchers";
import { formatCLP, convertDate } from "../../../../utils/Functions";
import moment from "moment";

import "./VouchersTable.scss";

const columns = [
  {
    title: "ID",
    dataIndex: "idDeuda",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Categoria",
    dataIndex: "categoria",
  },
  {
    title: "Propiedad",
    dataIndex: "propiedad",
  },
  {
    title: "F.Venc.",
    dataIndex: "fvencimiento",
    render: (fvencimiento) => moment(fvencimiento).format("DD-MM-yyy"),
    // render: (fecha) => fecha.substring(0, 10),
  },
  {
    title: "Monto",
    dataIndex: "monto",
    render: (monto) => formatCLP(monto),
  },
  {
    title: "Interes Mora",
    dataIndex: "interesMora",
    render: (interes) => formatCLP(interes),
  },
  {
    title: "Monto Total",
    dataIndex: "montoTotal",
    render: (montoTotal) => formatCLP(montoTotal),
  },
  {
    title: "Estado",
    dataIndex: "status",
    render: (status) => {
      let color = null;
      switch (status) {
        case "Vencida":
          color = "volcano";

          break;
        case "Pago por confirmar": //este deebria ser el estado para aquellos pagos no conciliados
          color = "yellow";

          break;
        case "Pagada": //pagos ya conciliados contra servipag
          color = "green";

          break;

        default:
          break;
      }

      // console.log("response.data status", status);
      return (
        <Tag color={color} key={status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
  },
  //   {
  //     title: "",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space size="middle">
  //         {/* <a>Invite {record.name}</a> */}
  //         <a>[CheckBox]</a>
  //       </Space>
  //     ),
  //   },
];

const VouchersTable = () => {
  const dataVouchersTable = useSelector(
    (state) => state.vouchersListReducer.VouchersList
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fMinima, setFMinima] = useState(null);
  // const [fMinima, setFMinima] = useState(new Date(2022, 6, 30));
  const dispatch = useDispatch();
  const dataUser = useSelector((state) => state.paymentRut.Rut);

  console.log("dataVouchersTable ", dataVouchersTable);
  console.log("dataVouchersTable fMinima", fMinima);

  const filtraArray = () => {
    const newArrayObj = map(dataVouchersTable, (itemArray, index) => {
      return {
        key: itemArray.key,
        ind: index,
        fvenc: new Date(
          parseInt(itemArray.fvencimiento.substring(0, 4)),
          parseInt(itemArray.fvencimiento.substring(5, 7)) - 1,
          parseInt(itemArray.fvencimiento.substring(8, 10))
        ),
      };
    });
    console.log("dataVouchersTable  newArrayObj", newArrayObj);

    if (selectedRowKeys.length > 0) {
      map(selectedRowKeys, (delKey) => {
        const index = newArrayObj.map((e) => e.key).indexOf(delKey);
        // console.log("dataVouchersTable index", delKey);
        // console.log("dataVouchersTable index", index);
        if (index !== -1) {
          newArrayObj.splice(index, 1);
        }
        //armar array comlpeto de salida pra flitro de fecha
      });
    }
    return newArrayObj;
  };

  const getFMinima = () => {
    // console.log("dataVouchersTable array obj.", arrayObj);
    // console.log(
    //   "dataVouchersTable array obj. selectedRowKeys",
    //   selectedRowKeys
    // );
    const arrayFiltrado = filtraArray();
    // const index = arrayObj.indexOf(selectedRowKeys);
    console.log("dataVouchersTable arrayFiltrado", arrayFiltrado.length);

    if (arrayFiltrado.length > 0) {
      return arrayFiltrado.reduce((c, n) => {
        return Date.parse(n.fvenc) < Date.parse(c.fvenc) ? n : c;
      });
    }
  };

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    const selectedRowsPrev = [];

    const selectedRows = map(newSelectedRowKeys, (selection) => {
      // selectedRows.push(
      // console.log("dataVouchersTable selection", selection);

      return dataVouchersTable.filter((data) => {
        // console.log("dataVouchersTable", data);
        return data.key.includes(selection);
      });
      // );
    });

    //hay  separa esta funcion para que haga la carga de reduxz en otra parte

    map(selectedRows, (row) => selectedRowsPrev.push(row[0]));

    console.log("selectedRowsPrev", selectedRowsPrev);
    console.log("selectedRowsPrev newSelectedRowKeys", newSelectedRowKeys);
    dispatch(selectedVouchers(selectedRowsPrev));
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length > selectionIndex) {
      setSelectionIndex(newSelectedRowKeys.length);
    } else {
      dispatch(selectedVouchers([]));
      setSelectedRowKeys([]);
      setSelectionIndex(0);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      console.log("record", record);
      const rowDate = new Date(
        parseInt(record.fvencimiento.substring(0, 4)),
        parseInt(record.fvencimiento.substring(5, 7)) - 1,
        parseInt(record.fvencimiento.substring(8, 10))
      );

      return {
        disabled: rowDate > fMinima,
      };
    },
    preserveSelectedRowKeys: false,
    hideSelectAll: true,
    // getCheckboxProps: (record) => ({
    //   disabled: record.name === 'Disabled User',
    //   // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    //   const unSelected = dataVouchersTable.filter(function (obj) {
    //     return map(selectedRowKeys, (selectedRowKey) => {
    //       return obj !== selectedRowKey;
    //     });
    //   });

    const fMinRow = getFMinima();
    // console.log("fMinRow selectedRowKeys", selectedRowKeys);
    console.log("fMinRow", fMinRow);
    if (fMinRow) {
      setFMinima(fMinRow.fvenc);
    }
  }, [dataVouchersTable, selectedRowKeys]);

  // useEffect(() => {
  //   const allWithClass = Array.from(
  //     document.getElementsByClassName('ant-checkbox-input"')
  //   );
  //   //console.log("allWithClass", allWithClass);
  // }, [dataUser]);

  return (
    <div className="VouchersTable__container">
      {/* <div className="VouchersTable__title">Cuentas disponibles para pago</div> */}

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataVouchersTable}
        pagination={false}
      />
      {/* <div>
        <Button
          type="primary"
          onClick={start}
          disabled={!hasSelected}
          loading={loading}
        >
          Recargar
        </Button>
      </div> */}
      <div className="VouchersTable__btmText">
        {hasSelected
          ? `${
              selectedRowKeys.length > 1
                ? `${selectedRowKeys.length} cuentas seleccionadas`
                : `${selectedRowKeys.length} cuenta seleccionada`
            }`
          : "No hay cuentas seleccionadas"}
      </div>
    </div>
  );
};
export default VouchersTable;
