// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import { vouchersListReducer } from "./vouchers";
import { paymentRut } from "./payhere";

const rootReducer = combineReducers({
  vouchersListReducer,
  paymentRut,
});

export default rootReducer;
