import React, { useEffect } from "react";

//redux
import { useDispatch } from "react-redux";
import { setPaymentRut } from "../../../redux/actions/payhere";

//Comps
import InputRut from "../../components/InputRut/InputRut";

//IMG
import payIMG from "../../../assets/svg/4202056_Artboard 1.svg";
//CSS
import "./Home.scss";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import HomeHeader from "../../components/HomeHeader/HomeHeader";

//GO
const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPaymentRut("191733908"));
  }, []);

  return (
    <div className="Home__container">
      <HomeHeader />
      <div className="Home__body">
        <div className="Home__layout">
          <div className="Home__imgContainer">
            <div className="Home__imgTitle">
              <h3 className="Home__text1">Bienvenido,</h3>
              <h5 className="Home__text2">
                Acá podrás pagar las cuentas de tu departamento
              </h5>
            </div>
            <div className="Home__img">
              <img src={payIMG} alt="portalPagos" />
              <div className="Home__greetings">
                <a href="https://www.freepik.com/free-vector/files-sent-concept-illustration_9793223.htm#query=receipt&position=7&from_view=search&track=sph">
                  Image by storyset
                </a>{" "}
                on Freepik
              </div>
            </div>
          </div>
          <div className="Home__input">
            <InputRut />
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Home;
