import { customAlphabet } from "nanoid/non-secure";

export function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCurrency(input, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var input_val = input.value;
  // don't validate empty input
  if (input_val === "") {
    return;
  }

  // original length
  var original_len = input_val.length;

  // initial caret position
  var caret_pos = input.getAttribute("selectionStart");

  // check for decimal
  if (input_val.indexOf(".") >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = `$${left_side}.${right_side}`;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = `$${input_val}`;

    // final formatting
    if (blur === "blur") {
      input_val += ".00";
    }
  }

  // send updated string to input
  input.value(input_val);

  // put caret back in the right position
  var updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0].setSelectionRange(caret_pos, caret_pos);
}

export const convertDate = (fecha, format) => {
  const newDate = new Date(fecha);
  return newDate;
};

export const formatCLP = (number) => {
  // return "$ " + number.toLocaleString("en-US");
  if (number) {
    return `$ ${Number(number.toFixed(1)).toLocaleString()}`;
  } else {
    return number;
  }
};
export const nanoid = () => {
  const id = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 4);
  const docid = id();
  return docid;
};

export const nanoidSol = () => {
  const id = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 6);
  const docid = id();
  return docid;
};
export const nanoidSolArrEmail = () => {
  const id = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 5);
  const docid = id();
  return docid;
};

export function dv(T) {
  var M = 0,
    S = 1;
  // for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;

  for (; T; T = Math.floor(T / 10)) {
    var alfa = T % 10;
    var beta = 9 - (M++ % 6);
    var gamma = alfa * beta;
    S = (S + gamma) % 11;
  }

  return S ? S - 1 : "k";
}

export function validaRut(rutCompleto) {
  rutCompleto = rutCompleto.replace("‐", "-");

  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
  var tmp = rutCompleto.split("-");
  var digv = tmp[1];
  var rut = tmp[0];
  if (digv === "K") digv = "k";

  return dv(rut) === digv;
}

export function validaRutStr(rutCompleto) {
  rutCompleto = rutCompleto.replace("‐", "-");
  // console.log(
  //   "data.txtrut validarut rutCompleto",
  //   !/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)
  // );

  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
  var tmp = rutCompleto.split("-");
  var digv = tmp[1];
  var rut = tmp[0];
  if (digv === "K") digv = "k";

  // console.log("data.txtrut validarut tmp", tmp);
  // console.log("data.txtrut validarut digv", digv);
  // console.log("data.txtrut validarut rut", rut);
  // console.log("data.txtrut validarut dv", dv(rut).toString());

  return dv(rut).toString() === digv;
}

export function isNumber(delKey) {
  return typeof delKey === "number" && isFinite(delKey);
}
export function isString(delKey) {
  return typeof delKey === "string" || delKey instanceof String;
}
