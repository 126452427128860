import React from "react";
//CSS
import "./HomeHeader.scss";
//IMG
import LogoKnockitW from "../../../assets/svg/logoKnockitW.svg";

const HomeHeader = () => {
  return (
    <div className="HomeHeader__container">
      <div className="HomeHeader__title">Portal de pago</div>
      <img className="HomeHeader__logo" src={LogoKnockitW} alt="logo" />
    </div>
  );
};

export default HomeHeader;
