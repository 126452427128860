import {useEffect, useState, useRef, React} from "react";
import Modal from "react-modal";
import Iframe from "react-iframe";
// import { Link } from "react-router-dom";
import {Select, Divider, Spin} from "antd";
// import { map } from "lodash";
// import MisSolicitudesArriendoCard from "../../components/MisSolicitudesArriendoCard";
// import MisSolicitudesCard from "../../components/MisSolicitudesCard";
// import EncabezadoCard from "../../components/MisSolicitudesCard/EncabezadoCard";
import {useSelector, useDispatch} from "react-redux";
import {
  setVouchersList,
  selectedVouchers,
} from "../../../redux/actions/vouchers";

// import ContentHeader from "../../../@core/layouts/components/contentHeader";
import getUrl from "../../../services/getUrl";
import {GiTerror} from "react-icons/gi";

//ICONS
import {TbHandRock} from "react-icons/tb";
import {GrConnect} from "react-icons/gr";

//COMPS
import VouchersTable from "./VouchersTable";
import VouchersCart from "./VouchersCart/VouchersCart";
// import VouchersCard from "./VouchersCard";

//CSS
import "./Vouchers.scss";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import {Link} from "react-router-dom";
// import KnockitSpinner from "../../components/KnockitSpinner";

// const dataVouchers = [
//   {
//     IDCode: "DA4362",
//     key: "DA4362",
//     propiedad: "Monseñor Edwards 2405, La Reina - Dpto. 1355",
//     categoria: "Arriendo",
//     fvencimiento: "23/02/2022",
//     monto: 550000,
//     status: ["Vencida"],
//     interesMora: 50000,
//     montoTotal: 600000,
//   },
//   {
//     IDCode: "JK5673",
//     key: "JK5673",
//     propiedad: "Agustinas 155, Santiago - Dpto. 336",
//     categoria: "Reserva",
//     fvencimiento: "23/03/2022",
//     monto: 550000,
//     status: ["Por Pagar"],
//     interesMora: 0,
//     montoTotal: 550000,
//   },
//   {
//     IDCode: "FR7834",
//     key: "FR7834",
//     propiedad: "Manquehue 2344, Las Condes - Dpto. 175",
//     categoria: "Arriendo",
//     fvencimiento: "23/03/2022",
//     monto: 550000,
//     status: ["Por Pagar"],
//     interesMora: 0,
//     montoTotal: 550000,
//   },
// ];

const Vouchers = () => {
  // const { Option } = Select;
  // const [datarequest, setDataRequest] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fueTimeout, setFueTimeout] = useState(false);
  const dispatch = useDispatch();

  const rutUser = useSelector((state) => state.paymentRut.Rut);
  const voucherList = useSelector(
    (state) => state.vouchersListReducer.VouchersList
  );
  // console.log("response.data rutUser ", rutUser);
  // console.log("response.data rutUser voucherlist", voucherList?.length);

  console.log("vouchers width", window.screen.width);
  dispatch(selectedVouchers([]));

  const GetData = () => {
    const api_ER = getUrl("Vouchers", "front");
    console.log("api_ER", api_ER);
    const rutLimpio = rutUser.replace(/[.-]/g, "");
    try {
      fetch(api_ER, {
        method: "POST",
        body: JSON.stringify({
          rut: rutLimpio,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resolve) => {
          return resolve.json();
        })
        .then((response) => {
          console.log("response.data response", response.data);
          dispatch(setVouchersList(response.data));
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      (typeof rutUser === "string" || rutUser instanceof String) &&
      rutUser !== ""
    ) {
      GetData();
      if (voucherList?.length >= 0) {
        setLoading(false);
      }
    }
  }, [rutUser]);

  // MPT 18082023 comento porq es incorrecto mostrar sin deudas por time out

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setFueTimeout(true);
        setLoading(false);
      }, 5000);
    }
  }, [loading]);
  const modalStyles = {
    content: {
      width: "70%",
      height: "70%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "20px", // Agrega un espacio de relleno si lo deseas
      border: "none",
      borderRadius: "10px",
      overflow: "auto",
    },
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const frameUrl =
    "https://botonpago.servipag.com/botonpago/standard/app/botondepago/4A2B4442733737706E45596B43643949476F784837667A7A2B5477745136463169537638676941344D342B756869433937326B3547616E35776D455665794838";
  return (
    <div className="vouchers__container">
      <HomeHeader />
      <div className="vouchers__body">
        {/* {rutUser !== "" && voucherList && voucherList?.length > 0 ? ( */}
        {!loading ? (
          <div className="vouchers__noRut__container">
            {voucherList?.length > 0 ? (
              <div className="vouchers__propList__container">
                <div className="vouchers__propList__filter">
                  <div className="vouchers__propList__filter__count">
                    Encontramos
                    {voucherList?.length > 1
                      ? ` ${voucherList?.length} cuentas pendientes`
                      : ` ${voucherList?.length} cuenta pendiente`}
                  </div>
                </div>
                <div className="vouchers__propList__body">
                  <VouchersTable />
                  <VouchersCart />
                </div>
              </div>
            ) : (
              <>
                {fueTimeout ? (
                  <>
                    {(typeof rutUser === "string" ||
                      rutUser instanceof String) &&
                    rutUser !== "" ? (
                      <>
                        <GrConnect className="vouchers__noRut__icon" />
                        <h3 className="vouchers__noRut__text">
                          Lo sentimos, estamos sin conexión... intenta mas tarde
                          porfavor
                        </h3>
                      </>
                    ) : (
                      <>
                        <GiTerror className="vouchers__noRut__icon" />
                        <h3 className="vouchers__noRut__text">
                          Lo sentimos, por favor vuelve a ingresar al portal
                        </h3>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TbHandRock className="vouchers__noRut__icon" />
                    <h3 className="vouchers__noRut__text">
                      No tienes cuentas pendientes
                    </h3>
                  </>
                )}
                <Link to={"/"}>
                  <button>Volver</button>
                </Link>
              </>
            )}
          </div>
        ) : (
          <div className="vouchers__spin">
            <Spin size="large" />
          </div>
        )}
      </div>
      <HomeFooter />
    </div>
  );
};

export default Vouchers;
