import { Routes, Route } from "react-router-dom";

import "./App.css";

//pages
import Home from "./views/pages/Home";
import Vouchers from "./views/pages/Vouchers";

function App() {
  return (
    <div className="App">
      <div className="App__inner">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vouchers" element={<Vouchers />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
