import { ActionTypes } from "../../constants/actionTypes";

export const setVouchersList = (vouchersList) => {
  return {
    type: ActionTypes.SET_VOUCHERSLIST,
    payload: vouchersList,
  };
};
export const selectedVouchers = (voucher) => {
  return {
    type: ActionTypes.SELECTED_VOUCHERS,
    payload: voucher,
  };
};
