import React, {useState, useEffect} from "react";
import {Space, Table, Tag, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {map} from "lodash";
// import { RiDeleteBin2Fill } from "react-icons/ri";
// import { MdDeleteForever } from "react-icons/md";
import {selectedVouchers} from "../../../../redux/actions/vouchers";
import {formatCLP} from "../../../../utils/Functions";
import moment from "moment";
import getUrl from "../../../../services/getUrl";
import XMLParser from "react-xml-parser";

import "./VouchersCart.scss";

const VouchersCart = () => {
  const [loading, setLoading] = useState(false);
  const [textXml, setTextXml] = useState("");
  const dispatch = useDispatch();
  const vouchersCartData = useSelector(
    (state) => state.vouchersListReducer.SelectedVouchers
  );
  const deleteRow = (record) => {
    // console.log("record", record);
    const newVouchersCartData = vouchersCartData.filter((data) => {
      return !data.key.includes(record.key);
    });
    // console.log("newVouchersCartData", newVouchersCartData);
    dispatch(selectedVouchers(newVouchersCartData));
  };

  const GetXml = (data) => {
    const api_SP = getUrl("Servipag", "front");
    try {
      fetch("https://api.back.knockit.rent/api/ServipagXml/Get", {
        method: "POST",
        body: JSON.stringify({
          fechaPago: data.FechaPago,
          montoTotalDeuda: data.MontoTotalDeuda,
          monto: data.Monto,
          fechaVencimiento: data.FechaVencimiento,
          nombreCliente: data.NombreCliente,
          rutCliente: data.RutCliente,
          emailCliente: data.EmailCliente,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resolve) => {
          return resolve.json();
        })
        .then((response) => {
          console.log("response.data response", response.data.xml);
          setTextXml(response.data.xml);
        });
    } catch (error) {}
  };

  const SavePaymentTemp = (data) => {
    const api_PT = getUrl("PaymentTemp", "front");
    console.log("datapay", data);
    try {
      fetch(api_PT, {
        method: "POST",
        body: JSON.stringify({
          idtTansaction: data.idtTansaction,
          billNumber: data.billNumber,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          debtsArray: data.debtsArray,
          jsonDebts: data.jsonDebts,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((resolve) => {
          return resolve.json();
        })
        .then((response) => {
          console.log("response.data response", response.data);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const sumaTotal = () => {
    let sumatoria = 0;

    map(vouchersCartData, (row) => {
      sumatoria = sumatoria + row.montoTotal;
    });

    return sumatoria;
  };

  useEffect(() => {
    var nombreClienteDebt;
    var RutClienteDebt;
    var emailClienteDebt;
    var fechaVencimientoDebt;

    map(vouchersCartData, (row) => {
      nombreClienteDebt = row.nombreCliente;
      RutClienteDebt = row.rutCliente;
      emailClienteDebt = row.emailCliente;
    });

    const currentTime = new Date();
    const convertTime = moment(currentTime).format("YYYYMMDD");

    var dataXml = {
      FechaPago: convertTime,
      MontoTotalDeuda: sumaTotal(),
      Monto: sumaTotal(),
      FechaVencimiento: convertTime,
      NombreCliente: nombreClienteDebt,
      RutCliente: RutClienteDebt,
      EmailCliente: emailClienteDebt,
    };

    GetXml(dataXml);
  }, [vouchersCartData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "idDeuda",
      // key: "idCode",
      // render: (text) => <a style={{ minWidth: "76px" }}>{text}</a>,
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
      // key: "categoria",
    },
    {
      title: "Monto Total",
      dataIndex: "montoTotal",
      // key: "montoTotal",
      render: (monto) => formatCLP(monto),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       {/* <a>Invite {record.name}</a> */}
    //       <a onClick={() => deleteRow(record)}>
    //         <MdDeleteForever className="VouchersCart__delete" />
    //       </a>
    //     </Space>
    //   ),
    // },
  ];

  const SendDataPaymentBoxApp = () => {
    var debtsConcat = "";
    const jsonDebt = [];
    var jsonPayment = "";
    var jsonDataFromXml = new XMLParser().parseFromString(textXml);
    var dataXml = jsonDataFromXml?.children[1].children;
    var dataXmlHeader = jsonDataFromXml?.children[0].children;
    var identificador;
    var boleta;
    var fechaVenc;

    if (dataXmlHeader?.length > 0) {
      map(dataXmlHeader, (row) => {
        if (row.name === "IdTxPago") {
          identificador = row.value;
        }
      });
    }

    if (dataXml?.length > 0) {
      map(dataXml, (row) => {
        if (row.name === "Boleta") {
          boleta = row.value;
        }
        if (row.name === "FechaVencimiento") {
          fechaVenc = row.value;
        }
      });
    }

    map(vouchersCartData, (row) => {
      /*eslint-disable */
      debtsConcat = debtsConcat + "," + row.idDeuda;
      jsonDebt.push({
        debtId: row.idDeuda,
        expiration: row.fvencimiento,
        rut: row.rutCliente,
        amount: row.monto,
        arrearsExpenses: row.interesMora,
        totalAmountServipag: sumaTotal(),
      });
    });

    jsonPayment = {
      idtTansaction: identificador,
      billNumber: boleta,
      createdAt: fechaVenc,
      updatedAt: fechaVenc,
      debtsArray: debtsConcat,
      jsonDebts: jsonDebt,
    };
    SavePaymentTemp(jsonPayment);
    console.log("jsonPayment", jsonPayment);
  };

  return (
    <form
      className="VouchersCart__form"
      method="POST"
      action="https://www.servipag.com/BotonPago/BotonPago/Pagar"
      target="_blank"
      accept-charset="UTF-8"
      wtx-context="748E2DF1-D93C-4B9B-AF0B-9B44E5927A72"
    >
      <input
        name="_token"
        type="hidden"
        value="l2W0B1S2xMwRyCENQpzQr9mWtXHcAffCZzmeRbJY"
        wtx-context="F61713E9-A7FF-4C7E-92D5-BA43B77C8706"
      />
      <input
        name="xml"
        type="hidden"
        // value="<?xml version='1.0' encoding='ISO-8859-1'?><Servipag><Header><FirmaEPS>ne0Y0O0yNVMEvgNfYp+PmSwoHHJidmNG/Ny/uxYMb/VCOHOOEPs4zQYKy2QqjkuWnCc+Q7ZexLo/Sqal05IyHCrYBkVNwvB+JwakN9ZWXpx8G6xbZHs7ahDvzmeDpHNeD0Lain4197XAkPtRSxkkAnqp91fQBBdXuMrlKV5Xiug=</FirmaEPS><CodigoCanalPago>606</CodigoCanalPago><IdTxPago>6425286240</IdTxPago><EmailCliente>escriba_correo_aqui@mail.com</EmailCliente><NombreCliente>JohanEscobarAcosta</NombreCliente><RutCliente>178076019</RutCliente><FechaPago>20220901</FechaPago><MontoTotalDeuda>1</MontoTotalDeuda><NumeroBoletas>1</NumeroBoletas><Version>2</Version></Header><Documentos><IdSubTx>1</IdSubTx><Identificador>664337790280181</Identificador><Boleta>109540</Boleta><Monto>1</Monto><FechaVencimiento>20220901</FechaVencimiento></Documentos></Servipag>"
        value={textXml}
        wtx-context="7B6297FB-C754-4E80-8D33-3F93A269E45F"
      />

      <div className="VouchersCart__container">
        <div className="VouchersCart__header">
          <div className="VouchersCart__title">Cuentas selecciondas:</div>
        </div>
        <div className="VouchersCart__body">
          <Table
            columns={columns}
            dataSource={vouchersCartData}
            pagination={false}
          />
          <div className="VouchersCart__totalContainer">
            Total: {formatCLP(sumaTotal())}
          </div>
        </div>

        <div className="VouchersCart__btnContainer">
          <input
            id="servipagbutton"
            className={
              vouchersCartData.length > 0
                ? "VouchersCart__btnServipag"
                : "VouchersCart__btnServipag btnServipag_d"
            }
            type="image"
            src="https://propertylink.cl/portalpago/public/images/servipag-btn.PNG"
            border="0"
            alt="Submit"
            onClick={(e) => SendDataPaymentBoxApp()}
            wtx-context="23B3DB49-D250-4FE6-89D4-6799CDDDA7D3"
            // hidden={!vouchersCartData.length > 0}
            loading={loading}
          />
        </div>

        {/* <Button
          type="Submit"
          //   onClick={start}
          disabled={!vouchersCartData.length > 0}
          loading={loading}
        >
          PAGAR
        </Button> */}
      </div>
    </form>
  );
};

export default VouchersCart;
