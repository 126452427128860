import { ActionTypes } from "../../constants/actionTypes";

// ** Initial State
const initialState = {
  Rut: "",
};

export const paymentRut = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PAYMENT_RUT:
      return { ...state, Rut: payload };
    default:
      return state;
  }
};
