import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
//REDUX
import { setPaymentRut } from "../../../redux/actions/payhere";

//CSS
import "./InputRut.scss";

const InputRut = () => {
  const [InputRut, setInputRut] = useState("");
  const dispatch = useDispatch();
  console.log("InputRut", InputRut);

  function handleclick(params) {
    dispatch(setPaymentRut(InputRut));
    //falta redireccionar a /vouchers
  }

  return (
    <div className="InputRut__container">
      {/* <h1 className="InputRut__title">Ingresa tu RUT:</h1> */}
      <div className="InputRut__Input">
        <input
          type="text"
          id="input"
          className="InputRut__InputText"
          placeholder="ej. 12345678K"
          onChange={(e) => setInputRut(e.target.value)}
        />
        <label htmlFor="input" className="InputRut__InputLabel">
          Rut o Pasaporte
        </label>
      </div>
      <Link to="/vouchers">
        <button onClick={handleclick}>Ver cuentas</button>
      </Link>
    </div>
  );
};

export default InputRut;
